import { useNavigate } from "react-router-dom";
import "./RevealScrollEl.css";

export default function RevealScrollEl(height: number, width: number) {

    function reveal() {
        var reveals = document.querySelectorAll(".reveal");

        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 150;

            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active");
            } else {
                reveals[i].classList.remove("active");
            }
        }
    }

    window.addEventListener("scroll", reveal);

    let navigate = useNavigate();
    const routeChangeContacts = () => {
        let path = `contacts`;
        navigate(path, { replace: true });
    };

    const captionButton = (buttonLabel: string) => {
        return (
            <button style={{fontSize: (((width + height)/120))}} className="cta" onClick={() => {
                if (buttonLabel === "Contact Us") {
                    routeChangeContacts();
                }
            }}>
                <span className={"hover-underline-animation" + ((width <= 580)? "-small": "")}> {buttonLabel} </span>
                <svg viewBox="0 0 46 16" height={(((width + height)/100))} width={(((width + height)/100))} 
                    xmlns="http://www.w3.org/2000/svg" id="arrow-horizontal">
                    <path transform="translate(30)" d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z" data-name="Path 10" id="Path_10"></path>
                </svg>
            </button>
        );
    }

    const captionBoxes = (title: string, body: string, buttonLabel: string) => {
        return (
            <div className={"container" + ((width <= 580) ? "Small" : "")} style={{ height: height / 3.73, width: width / 5, 
                margin: width / 19}}>
                <div className="card" style={{ height: height / 3.73, width: width / ((width <= 580) ? 1.8 : 5), 
                marginLeft: ((width <= 580) ? -width / 6 : 0) }}>
                    <p className="title" style={{
                        fontWeight: (width + height), fontFamily: "Luminari",
                        fontSize: ((width<=680? 1.2: 1) * ((width + height) / 100))
                        }}>
                        {title}
                    </p>
                    <div className="card-hidden">
                        <p className="title-in" style={{
                            fontWeight: (width + height), fontFamily: "Luminari",
                            fontSize: ((width<=680? 1.2: 1) * ((width + height) / 100))
                        }}>
                            {title}
                        </p>
                        <p style={{ fontFamily: "Century Gothic", fontSize: (((width<=680? 1.2: 0.7) * ((width + height) / 100)))}}>
                            {body}
                        </p>
                        <>{buttonLabel === "false" ? "" : captionButton(buttonLabel)}</>
                    </div>
                </div>
                <div className= "card-border" style={{width: width / ((width <= 580) ? 1.8 : 5),
                marginLeft: ((width <= 580) ? -width / ((width <= 480)? 5.05: 5.2) : 0)}}></div>
            </div>
        );
    };

    return (
        <section style={{ width: width }}>
            <div style={{ height: height / ((width <= 580) ? 0.9 : 1.8), width: width / 5.5}} className="container reveal">
                <h1 id="services" style={{ fontSize: (width + height) / 80, fontFamily: "Luminari" }} >Services</h1>
                <div className={"text-container" + ((width <= 580) ? "-small" : "")}>
                    <>{captionBoxes("How we work", "We study the problem and implement the most practical and concrete solution, until the complete realization and implementation of the project.", "false")}</>
                    <>{captionBoxes("Contacts", "Would you like to get in touch with us? Feel free to reach out through our contact form. To access the form, simply click the 'Contact Us' button below.", "Contact Us")}</>
                    <>{captionBoxes("Our support service", "You will receive prompt teleassistance service to constantly support your activity, thanks to remote control software integrated into the program.", "false")}</>
                </div>
            </div>
        </section>
    );
}
