import emailjs from "@emailjs/browser";
import "./ContactsUI.css";
import Toast from 'react-bootstrap/Toast';
import { useState } from "react";

export default function EmailForm(height: number, width: number) {

  const [showT, setShowT] = useState(false);

  const toggleShowT = () => { setShowT(!showT); }

  const sendToast = () => {
    return (
      <Toast style={{ position: "absolute", marginTop: (height / 100) * 78, marginLeft: "2%",
        width: (width / 100) * 20, height: (height / 100) * 14, overflowX: "hidden", 
        fontSize: (((width<=580? 1.2: 0.7) * ((width + height) / 100))) }} show={showT} onClose={toggleShowT}>
        <Toast.Header>
          <img
            src="holder.js/20x20?text=%20"
            className="rounded me-2"
            alt=""
          />
          <strong className="me-auto">Email sent correctly!</strong>
          <small>InfoCAD</small>
        </Toast.Header>
        <Toast.Body>Thanks for writing. We'll reply promptly.</Toast.Body>
      </Toast>

    );
  }


  const sendEmail = async (e: any) => {
    e.preventDefault();
    if (process.env.REACT_APP_INFOCAD_SERVICE_ID !== undefined &&
      process.env.REACT_APP_INFOCAD_TEMPLATE_ID !== undefined &&
      process.env.REACT_APP_INFOCAD_PUBLIC_KEY !== undefined) {
      emailjs.sendForm(process.env.REACT_APP_INFOCAD_SERVICE_ID, process.env.REACT_APP_INFOCAD_TEMPLATE_ID,
      e.target, process.env.REACT_APP_INFOCAD_PUBLIC_KEY);
      setShowT(true);
      setInterval(() => window.location.reload(), 1500);
    }
  }

  return (
    <>
      <form className="form" onSubmit={sendEmail} style={{ marginTop: height / 2.4, marginLeft: width /(width<=580? 3.1: 2.95), fontFamily: "Bradley Hand", 
        fontSize: (((width<=580? 1.2: 0.7) * ((width + height) / 100))), width: width<=580? "35%": "29%"}} >
        <div className="flex">
          <label>
            <input required type="text" name="nameFrom" className="input" />
            <span>name</span>
          </label>
          <label>
            <input required type="text" name="surnameFrom" className="input" />
            <span>surname</span>
          </label>
        </div>
        <label>
          <input required type="email" name="emailFrom" className="input" />
          <span>email</span>
        </label>
        <label>
          <input required type="tel" name="phoneFrom" className="input" />
          <span>contact number</span>
        </label>
        <label>
          <textarea required rows={3} name="messageFrom" className="input01" defaultValue={""} />
          <span>message</span>
        </label>
        <button className="fancy" >
          <span className="top-key" />
          <span className="text">submit</span>
          <span className="bottom-key-1" />
          <span className="bottom-key-2" />
        </button>
      </form>
      <>{sendToast()}</>
    </>
  );
}