import './App.css';
import { HashRouter, Route, Routes } from 'react-router-dom';
import Home from './mainPage/Home';
import ContactsUI from './contactsPage/ContactsUI';

function App() {

  return (
    <HashRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/contacts' element={<ContactsUI />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
