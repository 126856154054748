
export default function CredentialsOwner(height: number, width: number) {

    return (
        <div className="credentialsOwner" style={{
            marginLeft: width / 1.44, marginTop: height - height * 1.49,
            textAlign: "center", overflowY: "auto", fontSize: (((width<=580? 1.2: 0.7) * ((width + height) / 100))), 
            width: width <= 580? "27%": "13%", height: width <= 580? "": "43%"
        }}>
            <img src="/img/ownerProfile.jpg" className={"imageOwner" + ((width <= 580)? "Small": "")}></img>
            <div style={{ fontFamily: "Bradley Hand" }}>Farinelli, Giancarlo</div>
            <div style={{ fontFamily: "Bradley Hand" }}>
                <a target="_blank" href="https://mail.google.com/mail/?view=cm&source=mailto&to=farinelli@infocad.it"
                    style={{ textDecoration: "none", color: "black" }} >
                    farinelli@infocad.it {" "}
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="12" height="16" viewBox="0 5 26 26">
                        <path d="M 12.3125 0 C 10.425781 0.00390625 10.566406 0.507813 11.5625 1.5 L 14.78125 4.71875 L 9.25 10.25 C 8.105469 11.394531 8.105469 13.230469 9.25 14.375 L 11.6875 16.78125 C 12.832031 17.921875 14.667969 17.925781 15.8125 16.78125 L 21.34375 11.28125 L 24.5 14.4375 C 25.601563 15.539063 26 15.574219 26 13.6875 L 26 3.40625 C 26 -0.03125 26.035156 0 22.59375 0 Z M 4.875 5 C 2.183594 5 0 7.183594 0 9.875 L 0 21.125 C 0 23.816406 2.183594 26 4.875 26 L 16.125 26 C 18.816406 26 21 23.816406 21 21.125 L 21 14.75 L 18 17.75 L 18 21.125 C 18 22.160156 17.160156 23 16.125 23 L 4.875 23 C 3.839844 23 3 22.160156 3 21.125 L 3 9.875 C 3 8.839844 3.839844 8 4.875 8 L 8.3125 8 L 11.3125 5 Z"></path>
                    </svg>
                </a>
            </div>
            <div style={{ fontFamily: "Bradley Hand" }}>
                <svg style={{ padding: "3%" }} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width <= 680? 20: 30} zoomAndPan="magnify" viewBox="0 0 30 30.000001" height={width <= 680? 20: 30} preserveAspectRatio="xMidYMid meet" version={"1.0"}><defs><clipPath id="id1"><path d="M 19 6.132812 L 27.714844 6.132812 L 27.714844 24.277344 L 19 24.277344 Z M 19 6.132812 " clipRule="nonzero" /></clipPath><clipPath id="id2"><path d="M 2.308594 6.132812 L 11 6.132812 L 11 24.277344 L 2.308594 24.277344 Z M 2.308594 6.132812 " clipRule="nonzero" /></clipPath><clipPath id="id3"><path d="M 10 6.132812 L 20 6.132812 L 20 24.277344 L 10 24.277344 Z M 10 6.132812 " clipRule="nonzero" /></clipPath></defs><g clipPath="url(#id1)"><path fill="rgb(80.778503%, 16.859436%, 21.569824%)" d="M 27.703125 21.488281 C 27.703125 23.027344 26.441406 24.277344 24.882812 24.277344 L 19.242188 24.277344 L 19.242188 6.132812 L 24.882812 6.132812 C 26.441406 6.132812 27.703125 7.382812 27.703125 8.925781 Z M 27.703125 21.488281 " fillOpacity={1} fillRule="nonzero" /></g><g clipPath="url(#id2)"><path fill="rgb(0%, 57.249451%, 27.449036%)" d="M 5.140625 6.132812 C 3.582031 6.132812 2.320312 7.382812 2.320312 8.925781 L 2.320312 21.488281 C 2.320312 23.027344 3.582031 24.277344 5.140625 24.277344 L 10.78125 24.277344 L 10.78125 6.132812 Z M 5.140625 6.132812 " fillOpacity={1} fillRule="nonzero" /></g><g clipPath="url(#id3)"><path fill="rgb(93.328857%, 93.328857%, 93.328857%)" d="M 10.78125 6.132812 L 19.242188 6.132812 L 19.242188 24.277344 L 10.78125 24.277344 Z M 10.78125 6.132812 " fillOpacity={1} fillRule="nonzero" /></g></svg>
                +39 335-811-5829
            </div>
        </div>
    );
}