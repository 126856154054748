import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import '../mainPage/Home.css';

function NavBarContacts(height: number, width: number) {

  return (
    <AppBar position="fixed" style={{ backgroundColor: '#f5f5f5db' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            style={{marginLeft: "43%"}}
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'inherit',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img alt='logo' src="/img/logoInfocad.png" style={{ height: "4em", padding: "0.4em" }} />
          </Typography>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            style={{marginLeft: width / 4 + (width - 380) / 4}}
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'inherit',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img alt='logo' src="/img/logoInfocad.png" style={{ height: "3.3em", padding: "0.4em" }} />
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavBarContacts;