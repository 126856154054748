
export default function CredentialsInfocad(height: number, width: number) {

    return (
        <div className="credentialsInfocad" style={{
            marginLeft: width - width * 0.93, marginTop: height - height * 1.5,
            textAlign: "center", overflowY: "auto"
        }}>
            <div style={{paddingTop: "10%", paddingBottom: "5%", fontSize: (((width<=580? 1.2: 0.8) * ((width + height) / 100)))}}>
                <div style={{ fontFamily: "Bradley Hand" }}>INFOCAD by Farinelli Giancarlo</div>
                <div style={{ fontFamily: "Bradley Hand" }}>via Mons. Luigi di Liegro, N° 6</div>
                <div style={{ fontFamily: "Bradley Hand" }}>61040 Sant’Ippolito (PU)</div>
            </div>
        </div>
    );
}