import { useEffect, useState } from "react";
import NavBarContacts from "./NavBarContacts";
import "./ContactsUI.css"
import EmailForm from "./EmailForm";
import WelcomeText from "./WelcomeText";
import CredentialsOwner from "./CredentialsOwner";
import CredentialsInfocad from "./CredentialsInfocad";

export default function ContactsUI() {

    const [height, setHeight] = useState(window.innerHeight);
    const [width, setWidth] = useState(window.innerWidth);

    const updateDimensions = () => {
        setHeight(window.innerHeight);
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    return (
        <div className='contactsUI' style={{ maxHeight: height - 36, flex: 1, overflowY: "auto" }} >
            <>{NavBarContacts(height, width)}</>
            <>{EmailForm(height, width)}</>
            <>{WelcomeText(height, width)}</>
            <>{CredentialsOwner(height, width)}</>
            <>{CredentialsInfocad(height, width)}</>
        </div>
    );
}