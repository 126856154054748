import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function CarouselMain(height: number) {
  return (
    <Carousel fade>
      <Carousel.Item interval={5000}>
        <img alt='firstImage' className='imageCarousel' style={{flex: 1, height: height}} src="/img/carouselFirstImage.png" />
      </Carousel.Item>
      <Carousel.Item interval={5000}>
        <img alt='secondImage' className='imageCarousel' style={{flex: 1, height: height}} src="/img/carouselSecondImage.png" />
      </Carousel.Item>
      <Carousel.Item interval={5000}>
        <img alt='thirdImage' className='imageCarousel' style={{flex: 1, height: height}} src="/img/carouselThirdImage.png" />
      </Carousel.Item>
    </Carousel>
  );
}
