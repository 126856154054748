import { useEffect, useState } from 'react';
import './Home.css';
import NavBar from './Navbar';
import CarouselMain from './Carousel';
import RevealScrollEl from './revealScroll/RevealScrollEl';
import RevealText from './revealText/RevealText';

export default function Home() {

  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);

  const updateDimensions = () => {
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className='home' style={{ minHeight: height - 36, flex: 1 }} >
      <NavBar />
      <div style={{flex: 1, height: (width <= 580)? height / 2.5: height / 1.35}} className='carouselContainer'>
        <>{CarouselMain((width <= 580)? height / 2.5: height / 1.35)}</>
      </div>
      <>{RevealText(height, width)}</>
      <>{RevealScrollEl(height, width)}</>
    </div>
  );
}
