import "./RevealText.css";

export default function RevealText(height: number, width: number) {

    const scriptWhoAreWe = `InfoCAD operates as a service provider specializing in CAD (Computer-Aided Design) consultancy\
        and software development. The core of our expertise lies in the commercial furniture sector, encompassing furniture and shelving\
        solutions designed for the food and non-food retail sectors, alongside industrial shelving applications.\
        With over two and a half decades of industry presence, InfoCAD has accumulated substantial experience. \
        This wealth of knowledge enables to offer customized process optimization solutions, tailored meticulously to individual \
        client requirements. Our proficiency in this domain ensures the development of products that consistently meet the diverse \
        demands of various customer segments. Our commitment to precision and innovation is a testament to our dedication to \
        delivering high-quality solutions that adhere to industry standards.`

    function reveal() {
        var reveals = document.querySelectorAll(".reveal");

        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 150;

            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active");
            } else {
                reveals[i].classList.remove("active");
            }
        }
    }

    window.addEventListener("scroll", reveal);

    return (
        <section style={{ height: height , width: width }}>
            <div style={{ width: width / 5.5 }} className="container reveal">
                <h1 style={{ width: "50", height: "50", fontSize: (width + height) / 70, fontFamily: "Luminari" }} >Who we are</h1>
                <div id="about" className="text-container-RT" style={{ fontFamily: "Bradley Hand", textAlign: "center", 
                height: height / 2, width: width / 1.5, fontSize: (width + height) / ((width <= 680)? 80: 110) }}>
                    {scriptWhoAreWe}
                </div>
            </div>
        </section>
    );
}