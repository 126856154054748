
export default function WelcomeText(height: number, width: number) {

    const scriptWelcome = "Welcome to the official communication center of InfoCAD. We extend our sincere commitment to \
    providing excellent service and invite you to connect with us for any inquiries, assistance, or formal communication \
    needs. Below, you'll find the contact form to get in touch with us:"

    return (
        <section style={{ height: height, width: width }}>
            <div style={{ width: width / 5.5 }} className="welcomeContainer">
                <div id="about" className="welcomeText" style={{
                    fontFamily: "Bradley Hand",
                    textAlign: "center", height: height / 5, width: width / 2, marginTop: height - height * 1.3,
                    marginLeft: width - width * 1.17, fontSize: (((width<=580? 1.2: 0.8) * ((width + height) / 100)))
                }}>
                    {scriptWelcome}
                </div>
            </div>
        </section>
    );
}